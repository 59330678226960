import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPatient,
  updatePatientDetails,
  patientDischarge,
  getAllBedAssign,
  getAllBed,
} from "../../slices/thunk";
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { FaQrcode, FaSearch } from "react-icons/fa";
import patientImage from './../../assets/images/patientImage.png'
import {
  Modal, ModalFooter, Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardFooter, Badge, ModalHeader, ModalBody, List
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import uploadImage from "../../assets/images/rectangle-59991.svg";
import dischargeIcon from '../../assets/images/bed (2).png';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  MenuItem,
  IconButton,
  Select,
  TextField,
  Tooltip,
  Icon,
  Box
} from "@mui/material";
import { Bed, Search } from '@mui/icons-material';
import { baseURL, successCode } from "../../configuration/url";
import { BrowserMultiFormatReader } from "@zxing/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faBed, faDoorOpen, faPencil } from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import axios from 'axios';
import * as Constants from "../Constants/Constant";
import { toast } from "react-toastify";
import { authorize, formatDateToYYYYMMDD, formatSSN } from '../../helpers/common';
import Loader from "../../components/loader/Loader";
import { Paginator } from 'primereact/paginator'
import { HttpLogin } from "../../utils/Http";
import './newPatient.css'
import { getPatientSuccess, getTotalElementsSuccess } from "../../slices/patient/reducer";
import roomimg from "../../assets/images/room.svg";
import bedimg from "../../assets/images/bed.svg";
import LogoImg from "../../assets/images/profile.svg";
import "../bedAssign/bedassign.css";
import { CircularProgress } from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
const Patient: React.FC = () => {
  let [genderDropDown, setGenderDropDown] = useState(new Array<any>());
  const dispatch = useDispatch<any>();
  const [selectPatientId, setSelectPatientId] = useState<string | null>(null);
  const [editModal, setEditModal] = useState(false);
  const { loading, totalElements } = useSelector((state: any) => state.Patient);
  const { organization, userData } = useSelector((state: any) => state.Login);
  const userId = userData?.userDetail.id;
  const [bedAssignDialog, setBedAssignDialog] = useState(false);
  const [bedClick, setBedClick] = useState(false)
  const [patientAndBedAssign, setPatientAndBedAssign] = useState<any[]>([]);
  const [bedId, setBedId] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState('')
  const [scanning, setScanning] = useState(false);
  let [dischargeTransferId, setDischargeTransferId] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<any>([]);
  let [highlightdate1, sethighlightdate1] = useState(false);
  let [bedSelected, setBedSelected] = useState<string | null>(null);
  const codeReader = new BrowserMultiFormatReader();
  const videoRef = useRef(null);
  const [options, setOptions] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [admitD, setAdmitD] = useState<boolean>(false);
  const [reason, setReason] = useState('')
  const [searchTerm, setSearchTerm] = useState('');
  let [cityDropDown, setCityDropDown] = useState(new Array<any>());
  const [dateFieldError, setDateFieldError] = React.useState<string>('');
  const [error, setError] = React.useState<DateValidationError | null>(null);
  const [admitDate, setAdmitDate] = React.useState<dayjs.Dayjs | null>(null);
  let [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    ssn: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    mrNumber: "",
    email: "",
    beaconDevice1: [],
    gender: "",
    country: "",
    profile: ""
  });
  const [newPatientData, setNewPatientData] = useState<any[]>([]);
  useEffect(() => {
    getAllPatient(dispatch, organization, searchTerm ? 0 : currentPage, searchTerm ? searchTerm : '');
  }, [dispatch, organization, searchTerm]);
  const fetchPatientsandBedAssign = async () => {
    const { header1 } = authorize();
    try {
      const response = await axios.get(
        `${baseURL}/Q15Bed/getByOrg/${organization}`, { headers: header1 }
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setPatientAndBedAssign(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const fetchNewPatientData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/patient/get/activePatientMob/${organization}`
      );

      if (response.data.data && Array.isArray(response.data.data)) {
        setNewPatientData(response.data.data);
      } else {
        console.error("Invalid data format for patients:", response.data);
      }
    } catch (error) {
      console.warn(error);
    }
  }
  useEffect(() => {
    getAllBed(dispatch, organization);
    fetchNewPatientData();
  }, [dispatch, organization]);
  const { patientData } = useSelector((state: any) => state.Patient);
  const getPatientName = (patientId: string) => {
    const selectedPatient = newPatientData?.find((patient: any) => patient.id === patientId);

    if (selectedPatient) {
      if (selectedPatient.name && selectedPatient.name.length > 0) {
        const { family, given } = selectedPatient.name[0];
        const fullName = `${given} ${family}`;
        return fullName;
      } else if (selectedPatient.basicDetails && selectedPatient.basicDetails.length > 0) {
        const { family, given } = selectedPatient.basicDetails[0].name[0];
        const fullName = `${given} ${family}`;
        return fullName;
      }
    }
    console.warn(`Patient data issue for ID: ${patientId}`, selectedPatient);
    return "Unknown";
  };

  useEffect(() => {
    getAllBedAssign(dispatch, organization);
    getAllBed(dispatch, organization);
  }, [dispatch, organization]);

  useEffect(() => {
    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getPatientsBeacon?organization=${organization}`, { headers: header1 });
        setOptions(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchPatientsandBedAssign();
  }, [admitD]);

  const handleBedClick = (selectedBed: any) => {
    setBedSelected(selectedBed.roomNo + "-" + selectedBed.bedNo);
    const bedAssignId = selectedBed.id || " ";
    setBedId(bedAssignId);
    setBedClick(true)
  };

  const handleDioCancel = () => {
    setBedAssignDialog(false);
    setBedClick(false);
    setAdmitD(false)
    setAdmitDate(null);
    setDateFieldError('');
    setError(null);
  }



  const errorMessage = React.useMemo(() => {
    if (dateFieldError) return dateFieldError;
    switch (error) {
      case 'maxDate':
      case 'minDate':
        return 'Please select a date in the first quarter of 2022';
      case 'invalidDate':
        return 'Your date is not valid';
      default:
        return '';
    }
  }, [error, dateFieldError]);
  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      const constraints = {
        video: { deviceId: selectedDeviceId },
      };
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            let newDeviceId = "";
            const result1 = result.getText();
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
              if (result1.length < 15) {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId;
              } else {
                const resultId = result1.match(/.{2}/g).join(':');
                newDeviceId = resultId.slice(6, 23);
              }
              setShow(false);
              setScanning(false);
            }
            const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);
            if (scannedOption) {
              setDeviceId(scannedOption.deviceId)
              const modal = document.getElementById("exampleModal");
              if (modal) {
                modal.classList.add("show");
                modal.style.display = "block";
              }
            } else {
              setDeviceId("");
              toast.error("Scanned device ID not found in the options.");
            }
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };
  const [show, setShow] = useState(false);

  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };

  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  const handleQrClick = () => {
    setShow(true);
    setScanning(!scanning);
  }

  const hiddenFileInput = useRef<any>(null);

  const handleProfileChange = () => {
    if (hiddenFileInput !== null) {
      hiddenFileInput.current.click();
    }
  }

  const handleImageChange = (e: any) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      formData.profile = reader.result !== null ? reader.result.toString() : ""
      setFormData({ ...formData });

    }
    reader.readAsDataURL(e.target.files[0]);
  }


  const bedsByNumber = patientAndBedAssign.reduce<{ [key: string]: any[] }>((acc, bedassign) => {
    const { roomNo, bedNo } = bedassign;

    if (!acc[roomNo]) {
      acc[roomNo] = [];
    }
    acc[roomNo].push(bedassign);
    return acc;
  }, {});
  const handleSave = async () => {
    if (!admitDate) {
      setDateFieldError('Date field cannot be empty.');
      return;
    }
    const { header1 } = authorize();
    try {
      const formattedDate = formatDateToYYYYMMDD(admitDate);
      const requestBody = {
        bedId: bedId,
        pid: selectPatientId,
        transferedBy: userId,
        transferDate: formattedDate,
        transferReason: reason,
        deviceId: deviceId
      };
      const requestBody1 = {
        roomBed: roomNo,
        pid: selectPatientId,
        orgId: organization,
        dischargeDate: formattedDate,
        dischargedBy: userId,
        dischargeReason: reason
      }
      const response = await axios.post(
        `${baseURL}/Q15Bed/${dischargeTransferId === '1' ? 'transfer' : 'dischargePatient'}`,
        dischargeTransferId === '1' ? requestBody : requestBody1, { headers: header1 }
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) {
        handleDioCancel()
        getAllPatient(dispatch, organization, currentPage, '');
        getAllBedAssign(dispatch, organization);
        getAllBed(dispatch, organization);
        toast.success(response.data.message.description)
        setBedAssignDialog(false)
        setBedClick(false)
        setAdmitD(false)
      } else {
        console.error("Error:", response.data.message);
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [loading1, setLoading] = useState(false);
  const handlePostalCodeChange = async (e: any) => {
    formData.postalCode = e.target.value;
    setFormData({ ...formData });
    if (e.target.value.length === 5) {
      setLoading(true);
      try {
        const response = await axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}&country=US&${Constants.apiKey}`);
        formData.state = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].state : "";
        formData.country = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].country_code : "";
        formData.city = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].city : "";
        setCityDropDown(response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]]?.map((k: any) => k.city_en) : [])
      } catch (error) {
        console.error('Error fetching city:', error);
      } finally {
        setLoading(false);
      }
    } else {
      formData.state = "";
      formData.country = "";
      formData.city = "";
      setLoading(false);
    }
    setFormData({ ...formData });
  };
  const handleAddress = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleinputchange = (event: any) => {
    if (event.target.id === 'firstName') {
      formData.firstName = event.target.value;
    } else if (event.target.id === 'middleName') {
      formData.middleName = event.target.value;
    } else if (event.target.id === 'lastName') {
      formData.lastName = event.target.value;
    } else if (event.target.id === 'mrNumber') {
      formData.mrNumber = event.target.value;
    } else if (event.target.id === 'ssn') {
      formData.ssn = event.target.value;
    } else if (event.target.id === 'email') {
      formData.email = event.target.value;
    } else if (event.target.id === 'addressLine1') {
      formData.addressLine1 = event.target.value;
    } else if (event.target.id === 'AddressLine2') {
      formData.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formData.city = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'country') {
      formData.country = event.target.value;
    }
    setFormData({ ...formData });
  }

  const handleSaveChanges = () => {
    if (!selectPatientId) {
      return;
    } else if (formData.postalCode.length > 0 && formData.city === "" && formData.state === "") {
      formData.city = "";
      formData.state = "";
      formData.country = "";
      alert("Please Enter Valid Zip Code");
      return;
    }
    const formatDateold = (dateString: any) => {
      const [year, month, day] = dateString.split('-');
      return `${year}${month}${day}`;
    };
    const updatedPatientFields = {
      id: selectPatientId,
      basicDetails: [
        {
          name: [
            {
              use: formData.middleName,
              given: formData.firstName,
              family: formData.lastName,
            },
          ],
          profile: formData.profile,
          ssn: formData.ssn,
          mrNumber: formData.mrNumber,
          gender: formData.gender,
          birthDate: formatDateold(formData.birthDate),
        },
      ],
      email: formData.email,
      organization,
      contact: [
        {
          address: [
            {
              addressLine1: formData.addressLine1,
              addressLine2: formData.addressLine2,
              city: formData.city,
              state: formData.state,
              postalCode: formData.postalCode,
              country: formData.country,
            },
          ],
        },
      ],
      deviceId: deviceId,
    };
    dispatch(
      updatePatientDetails(
        selectPatientId,
        updatedPatientFields,
        organization,
        currentPage
      )
    );
    setEditModal(false);
  };
  useEffect(() => {
    const fetchDropdownData = () => {
      const { header1 } = authorize();
      HttpLogin.axios().get(`/api/dropdowns/getByDropdown?dropdown=gender`, { headers: header1 })
        .then((response) => {
          if (response.data.data !== null && response.data.data !== successCode) {
            setGenderDropDown(response.data.data[0].list);
          }
        })
    }
    fetchDropdownData();
  }, []);


  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }
  const [patientname, setpatientname] = useState('');
  const [roomNo, setRoomNo] = useState('');

  const handleTransfer = (patient: any) => {
    const fullname = `${patient.basicDetails[0].name[0].given || ''} ${patient.basicDetails[0].name[0].family || ''}`;
    setpatientname(fullname);
    setRoomNo(patient.assignedBed ? patient.assignedBed : "")
    setBedAssignDialog(true);
    setDeviceId(patient.beaconDevice)
    setAdmitDate(null);
    setReason("");
    setDischargeTransferId("1");
    setSelectPatientId(patient.id);
  }

  const handleDis = (patient: any) => {
    const fullname = `${patient.basicDetails[0].name[0].given || ''} ${patient.basicDetails[0].name[0].family || ''}`;
    setpatientname(fullname);
    setRoomNo(patient.assignedBed ? patient.assignedBed : "")
    setBedAssignDialog(true);
    setDeviceId("");
    setAdmitDate(null);
    setReason("");
    setDischargeTransferId("2");
    setSelectPatientId(patient.id);
  }
  const formatDateForDisplay = (dateString: any) => {
    if (!dateString || dateString.length !== 8) return '';
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${month}-${day}-${year}`;
  };
  const formatDateForInput = (dateString: any) => {
    if (!dateString || dateString.length !== 8) return '';
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${year}-${month}-${day}`;
  };
  const handleClick = (selectedPatient: any) => {
    if (selectedPatient) {
      const fullName = `${selectedPatient.basicDetails[0].name[0].given || ''} ${selectedPatient.basicDetails[0].name[0].family || ''}`;
      setpatientname(fullName);
      const patientId = selectedPatient.id || "";
      setSelectPatientId(patientId);
      setDeviceId(selectedPatient.beaconDevice)
      const basicDetails = selectedPatient.basicDetails[0];
      const address = selectedPatient.contact[0]?.address[0];
      setFormData({
        firstName: basicDetails.name[0]?.given || "",
        middleName: basicDetails.name[0]?.use || "",
        lastName: basicDetails.name[0]?.family || "",
        birthDate: formatDateForInput(basicDetails.birthDate) || "",
        ssn: basicDetails.ssn || "",
        addressLine1: address?.addressLine1 || "",
        addressLine2: address?.addressLine2 || "",
        city: address?.city || "",
        state: address?.state || "",
        postalCode: address?.postalCode || "",
        mrNumber: basicDetails.mrNumber || "",
        email: selectedPatient.email || "",
        beaconDevice1: selectedPatient.beaconDevice1 || [],
        gender: basicDetails.gender || "",
        country: address?.country || "",
        profile: basicDetails.profile || "",
      });
      setEditModal(true);
    } else {
      console.error("Invalid patient data:", selectedPatient);
    }
  };

  return (
    <div className="container m5 p3" style={{ width: '90%' }}>
      {loading && <Loader />}
      <div className="row" style={{ position: "relative" }}>
        <div className="col-md-8 d-flex align-items-center">
          <h5>Active Patient List</h5>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              onChange={handleSearch}
            />
            <FaSearch className="search-icon mt-1" />
          </div>

        </div>
        <div className="row">
          <div className="col-md-3">

          </div>
        </div>
        <table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead>
            <tr>
              <th scope="col" style={{ width: '13px' }} className="text-center table-data">S.No</th>
              <th scope="col" className="text-center table-data">Patient Name</th>
              <th scope="col" className="text-center table-data">SSN</th>
              <th scope="col" className="text-center table-data">Date Of Birth</th>
              <th scope="col" className="text-center table-data">Beacon Device</th>
              <th scope="col" className="text-center table-data">Room No - Bed No</th>
              <th scope="col" className="text-center table-data">Action</th>
            </tr>
          </thead>
          <tbody>
            {patientData.length > 0 ? patientData?.map((patient: any, index: number) => (
              <tr key={index}>
                <td className="text text-center">{currentPage * 10 + index + 1}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleClick(patient)
                  }
                  className="text"
                >
                  {patient.basicDetails[0].name[0].given}{" "}
                  {patient.basicDetails[0].name[0].family}
                </td>
                <td className="text">{'***' + patient.basicDetails[0].ssn.slice(-4)}</td>
                <td className="text">{formatDateForDisplay(patient.basicDetails[0].birthDate)}</td>
                <td className="text">{patient.beaconDevice}</td>
                <td className="text">{patient.assignedBed}</td>
                <td className="text d-flex justify-content-around align-items-center">
                  <Tooltip title="Edit" arrow>
                    <FontAwesomeIcon
                      icon={faPencil}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleClick(patient)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Transfer" arrow>
                    <img
                      src={dischargeIcon}
                      alt="Discharge Icon"
                      className="text-danger"
                      style={{ cursor: "pointer", width: '16px', height: '18px' }}
                      onClick={() => {
                        handleTransfer(patient)
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Discharge" arrow>
                    <FontAwesomeIcon
                      icon={faBan}
                      className="text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleDis(patient)
                      }}
                    />
                  </Tooltip>
                </td>
              </tr>
            )) : 
            <tr>
              <td colSpan={7} className="text-center">
                <h5>No Active Patients found</h5>
              </td>
            </tr>
          }
          </tbody>
        </table>
      </div>
      <div className="pagination-container" >
        <div className="d-flex justify-content-center">
          <Paginator
            first={currentPage * 10}
            rows={10}
            totalRecords={totalElements}
            onPageChange={(e: any) => {
              setCurrentPage(e.page)
              getAllPatient(dispatch, organization, e.page, searchTerm ? searchTerm : '')
            }}
            currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
          />
        </div>
      </div>
      <Modal
        isOpen={editModal}
        // toggle={() => setEditModal(false)}
        centered
        size="lg"
      ><div className="d-flex align-items-center justify-content-center vh-90">
          <div className="row">
            <div className="container col-md-12">
              <ModalHeader toggle={() => setEditModal(false)}>{patientname}<span>
                <div onClick={handleProfileChange} hidden={formData.profile !== null && formData.profile !== "" && formData.profile !== "string"} style={{ left: "unset", width: "156px" }} className="vector-group">
                  <img className="frame-child4" style={{ cursor: 'pointer' }} alt="" src={uploadImage} />
                  <div className="upload-profile-pic1" style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>UPLOAD PROFILE PIC</div>
                </div>
                <div hidden={formData.profile === null || formData.profile === "" || formData.profile === "string"} style={{ left: "unset", width: "182px" }} className="vector-group">
                  <div style={{ position: 'absolute', top: "2px" }}><img style={{ height: '50px', width: '50px', borderRadius: "60px" }} src={formData.profile} onClick={handleProfileChange} /><input className="vector-group" style={{ position: 'absolute', top: '12px', display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleImageChange} /></div>
                </div>
              </span></ModalHeader>
              <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                <div className="row w-100 ">
                  <div className="col-md-4">
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      placeholder="Enter First Name"
                      value={formData.firstName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="middleName"
                      name="middleName"
                      label="Middle Name"
                      placeholder="Enter Middle Name"
                      value={formData.middleName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter LastName"
                      value={formData.lastName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mt-3">
                    <Autocomplete
                      id="natureOfReaction"
                      options={genderDropDown?.map((item: any) => item.value)}
                      value={formData.gender}
                      onChange={(e, v) => { formData.gender = v; setFormData({ ...formData }); }}
                      sx={{ width: "100%" }}
                      size="medium"
                      renderInput={params =>
                        <TextField
                          name="natureOfReaction"
                          {...params}
                          variant="outlined"
                          label="Gender"
                          placeholder="Nature of Reaction"
                          margin="none"
                          size="medium"
                          fullWidth
                        />
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      type="date"
                      id="birthDate"
                      name="birthDate"
                      label="Date Of Birth"
                      placeholder="Enter DateOfBirth"
                      value={formData.birthDate}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="mrNumber"
                      name="mrNumber"
                      label="MRN#"
                      value={formData.mrNumber}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      id="ssn"
                      name="ssn"
                      label="SSN"
                      placeholder="Enter SSN"
                      value={formData.ssn}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4">
                    <TextField
                      id="addressLine1"
                      name="addressLine1"
                      label="Address Line 1"
                      placeholder="Enter Address"
                      value={formData.addressLine1}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="addressLine2"
                      name="addressLine2"
                      label="Address Line 2"
                      placeholder="Enter Address"
                      value={formData.addressLine2}
                      onChange={handleAddress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mt-3">
                    <TextField
                      id="zipcode"
                      label="Zip/Postal Code"
                      variant="outlined"
                      fullWidth
                      value={formData.postalCode}
                      onChange={handlePostalCodeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 5,
                        pattern: "\\d{5}",
                      }}
                    />
                    {loading1 && <CircularProgress size={'25px'} />}
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mt-3">
                    {cityDropDown !== null && cityDropDown.length === 1 ?
                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={formData.city}
                        onChange={handleinputchange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> :
                      <Autocomplete
                        id="city"
                        options={cityDropDown}
                        value={formData.city}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) => { formData.city = v; setFormData({ ...formData }); }}
                        sx={{ width: "100%" }}
                        size="medium"
                        renderInput={params =>
                          <TextField
                            name=""
                            {...params}
                            variant="outlined"
                            label="City"
                            placeholder=""
                            margin="none"
                            size="medium"
                            fullWidth
                          />
                        }
                      />}
                  </div>
                  <div className="col-md-4 mt-3">
                    <TextField
                      id="state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      value={formData.state}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="country"
                      name="country"
                      label="Country"
                      placeholder="Enter Country"
                      value={formData.country}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100 d-flex">
                  <p>Device Id</p>
                  <div className='col-md-6' style={{ position: 'relative', paddingLeft: 0, padding: 0 }}>
                    <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                      <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                        <Select
                          // multiple
                          value={deviceId}
                          onChange={(e) => setDeviceId(e.target.value)}
                          style={{ minHeight: '50px', width: '300px', marginLeft: '12px' }}
                          IconComponent={() => (
                            <FaQrcode
                              className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                              role='button'
                              style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                            />
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '300px',
                              },
                            },
                          }}
                        >
                          {options?.map((option: any) => (
                            <MenuItem key={option.id} value={option.deviceId}>
                              {option.deviceId}
                            </MenuItem>
                          ))}
                        </Select>
                      </ListItem>
                    </List>
                  </div>
                </div>
              </ModalBody>
            </div>
          </div>
        </div>
        <ModalFooter className="">
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setEditModal(false)}></Button>
            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveChanges}></Button>
          </div>
        </ModalFooter>
      </Modal>
      <Dialog maxWidth={'xl'} PaperProps={{ sx: { width: dischargeTransferId === "1" ? '67%' : '40%', maxWidth: '67%', position: 'absolute', height: dischargeTransferId === "1" ? '84vh' : '50vh', top: '40px' } }}
        open={bedAssignDialog}
      >
        <DialogTitle >{dischargeTransferId === "1" ? 'Transfer' : 'Discharge'}</DialogTitle>
        <DialogContentText >
          <DialogContent style={{ padding: '20px', background: '#F8FAFB' }}>
            <div>
              <>
                <div className="row w-100 mb-3">
                  <div className="col-md-12">
                    <TextField id="Reason" label="Patient Name" variant="outlined" value={patientname} fullWidth />
                  </div>
                </div>
                <div className="row w-100 ">
                  <div className='col-md-6 mb-2' >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box >
                        <DatePicker
                          sx={{ width: "100%" }}
                          defaultValue={dayjs('2022-07-17')}
                          onError={(newError) => setError(newError)}
                          label={dischargeTransferId === "1" ? 'Transfer Date' : 'Discharge Date'}
                          value={admitDate}
                          onChange={(newValue) => {
                            setAdmitDate(newValue);
                            if (dateFieldError) setDateFieldError('')
                          }}
                          slotProps={{
                            textField: {
                              helperText: errorMessage,
                              error: Boolean(dateFieldError || error),
                            },
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  </div>
                  <div className='col-md-6 mb-2'>
                    <TextField id="Reason" label={dischargeTransferId === "1" ? 'Transfer Reason' : 'Discharge Reason'} variant="outlined" value={reason} fullWidth onChange={(e) => setReason(e.target.value)} />
                  </div>
                  <div className="row w-100 ">
                    <div className='col-md-6 mb-2' style={{ textAlign: 'left' }}>{dischargeTransferId === "1" && "Device-Id"}</div>
                    <div className='col-md-3 mb-2' ></div>
                    <div className='col-md-3 mb-2' ></div>
                  </div>
                  {dischargeTransferId === "1" && <>
                    <div className='col-md-6 mb-2'>
                      <List sx={{ pt: 0 }} style={{ margin: 0, padding: 0 }}>
                        <ListItem disableGutters style={{ margin: 0, padding: 0 }}>
                          <Select
                            // multiple
                            value={deviceId}
                            onChange={(e) => setDeviceId(e.target.value)}
                            style={{ minHeight: '50px', width: '100%', marginLeft: '12px' }}
                            IconComponent={() => (
                              <FaQrcode
                                className="position-absolute bottom-0 m-3" onClick={handleQrClick}
                                role='button'
                                style={{ fontSize: '1.2rem', color: '#000', right: '0', cursor: 'pointer', margin: 0, padding: 0 }}
                              />
                            )}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: '300px',
                                },
                              },
                            }}
                          >
                            {options?.map((option: any) => (
                              <MenuItem key={option.id} value={option.deviceId}>
                                {option.deviceId}
                              </MenuItem>
                            ))}
                          </Select>
                        </ListItem>
                      </List>
                    </div>
                    <div className="col-md-6 mb-2" style={{ textAlign: 'end' }}>
                      <TextField
                        label="Room-Bed"
                        type="text"
                        placeholder=''
                        margin="none"
                        fullWidth
                        value={bedClick && bedSelected ? bedSelected : roomNo}
                        disabled={true}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => {
                              if (admitD === false) {
                                setAdmitD(true);
                              } else {
                                setAdmitD(false);
                              }
                            }} edge="end">
                              <Bed />
                            </IconButton>
                          )
                        }}
                      />
                    </div></>}
                </div>
              </>
              <div className="container m15 p3" >
                <div className="grid-templetcontainer" style={{ justifyItems: "center" }} >
                  {admitD && Object.entries(bedsByNumber)?.map(([bedNo, assignments]) => (
                    <Row key={bedNo} style={{ display: "flex", flexWrap: "wrap", justifyContent: bedNo === '1' ? 'space-evenly' : 'flex-start', marginBottom: "40px" }}>
                      {assignments?.map((bedassign, index) => (
                        bedassign.pid !== null ? <>
                          <Col key={bedassign.id} style={{ flex: 0, padding: 0 }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="danger"
                                outline
                                style={{
                                  width: "78px",
                                  height: "70px",
                                  margin: "5px",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                  </CardTitle>
                                  <CardSubtitle tag="h6" className=" text-muted">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                                  </CardSubtitle>
                                </CardBody>
                                <CardFooter style={{
                                  padding: '7px', position: 'relative', display: 'flex', top: '-13px', height: '33px', fontSize: '10px', fontWeight: 'bold', lineHeight: 'normal', justifyContent: "space-evenly",
                                  background: bedNo === '01' ? '#f8b19570' : (bedNo === '02' ? '#c06c8470' : (bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                }}>
                                  <img src={LogoImg} style={{ width: '20px', height: '20px' }}></img><span className="truncate-hover" style={{ paddingLeft: '5px', lineHeight: 2 }}>{getPatientName(bedassign.pid)}{patientAndBedAssign.filter((item: any) => item.roomNo === bedassign.roomNo).length}</span>
                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        </> : <>
                          <Col key={index} style={{ flex: 0, padding: 0 }}>
                            <div className="bed-assignment-box">
                              <Card
                                className="mb-3"
                                color="primary"
                                outline
                                style={{
                                  width: "78px",
                                  height: "70px",
                                  margin: "5px",
                                  border: bedassign.id === bedId ? "groove" : "",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <CardBody
                                  key={index}
                                  onClick={() => handleBedClick(bedassign)}
                                  style={{ cursor: "pointer", padding: '0.6rem', display: "flex", alignItems: "center", justifyContent: "space-between", width: "108%" }}
                                >
                                  <CardTitle tag="h6">
                                    <img src={roomimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: (bedassign.roomNo).length > 2 ? '10px' : '12px', fontWeight: 'bold' }}>{bedassign.roomNo}</span>
                                  </CardTitle>
                                  <CardSubtitle tag="h6" className="text-muted ">
                                    <img src={bedimg} style={{ width: '15px', height: '20px' }} />
                                    <span style={{ marginLeft: '3px', fontSize: '12px', fontWeight: 'bold' }}>{bedassign.bedNo}</span>
                                  </CardSubtitle>
                                </CardBody>

                                <CardFooter style={{
                                  padding: '2px 0.6rem 5px 13px ', position: 'relative', top: '-13px', height: '33px',
                                  background: bedNo === '01' ? '#f8b19570' : (bedNo === '02' ? '#c06c8470' : (bedNo === '03' ? '#355c7d52' : '#FFFFFF'))
                                }}>

                                  <Badge
                                    style={{ fontSize: '10px' }}
                                    color={bedassign.pid ? "danger" : "success"}
                                    tag="h6"
                                  >
                                    {bedassign.pid ? "Not Available" : "Available"}
                                  </Badge>
                                </CardFooter>
                              </Card>
                            </div>
                          </Col>
                        </>
                      ))}
                    </Row>
                  ))}
                </div>
              </div>
            </div>
          </DialogContent>
        </DialogContentText>
        <div className="d-flex gap-3 mb-3 justify-content-center">
          <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }}
            onClick={handleDioCancel}></Button>
          <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
        </div>
      </Dialog>
      <Dialog open={show} onClose={handleClose1}>
        <DialogTitle>Scanning</DialogTitle>
        <DialogContent>
          <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
        </DialogContent>
        <div className="d-flex gap-3 justify-content-center">
          <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
          <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Patient;