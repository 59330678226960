import axios from 'axios'
import { isLoading, setErrorMessage, setIsLoadingFalse, getShiftStartTimeSuccess, getShiftDurationSuccess, getOrgDataByIdSuccess } from "./reducer"
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';

export const getOrgByID = async (dispatch: any,orgId:any) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
        const response = await axios.get(`${baseURL}/org/getById/${orgId}`,{headers: header1});
        dispatch(setIsLoadingFalse())
      if (response.data.message.code === successCode) {
        dispatch(getOrgDataByIdSuccess(response.data.data))
        dispatch(getShiftStartTimeSuccess(response.data.data.shift.startTime));
        dispatch(getShiftDurationSuccess(response.data.data.shift.duration));
      } else {
        dispatch(setErrorMessage(response.data.message.description));
    dispatch(setIsLoadingFalse())
      }
    } catch (error) {
      dispatch(setIsLoadingFalse());
    }
  };
