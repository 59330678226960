export function formatDateToYYYYMMDD(dateObject: any) {
    if(!dateObject) return ''
    const year = dateObject.$y
    const month = (dateObject.$M + 1).toString().padStart(2, '0')
    const day = dateObject.$D.toString().padStart(2, '0')
    const formattedDate: string = `${year}${month}${day}`
    return formattedDate
}

export function formatPhoneNumber (value:any) {
    if (!value) return ""
    const phoneNumber = value?.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export function formatSSN(ssn: string): string {
    if (!ssn) return '';
    const cleanedSSN = ssn.replace(/[^\d]/g, '');
    const limitedSSN = cleanedSSN.slice(0, 9);
    const ssnLength = limitedSSN.length;

    if (ssnLength < 4) {
        return limitedSSN;
    } else if (ssnLength < 6) {
        return `${limitedSSN.slice(0, 3)}${limitedSSN.slice(3)}`;
    } else {
        return `${limitedSSN.slice(0, 3)}${limitedSSN.slice(3, 5)}${limitedSSN.slice(5)}`;
    }
}

export function authorize() {
    const jwt = localStorage.getItem('jwt');
    const header1 = { Authorization: 'Bearer ' + jwt } 
    return { header1 };
}

export const formatDate1 = (dateString: any) => {
    if (!dateString) return '';

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${month}-${day}-${year}`;
};