import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./beacon.css";
import { baseURL, successCode } from "../../configuration/url";
import { TbDeviceWatchPlus } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllBeacon, updatedSensorDetails } from "../../slices/beaconDevices/thunk";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  TextField,
  Tooltip,
  Autocomplete
} from "@mui/material";
import { Button } from 'primereact/button';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FaArrowAltCircleLeft, FaQrcode, FaSearch } from "react-icons/fa";
import BeaconCreation from "./beaconCreation";
import { BrowserMultiFormatReader } from "@zxing/library";
import Trashimg from "./../../assets/images/VscTrash.png"
import editimg from "./../../assets/images/Vectorpencil.png"
import { authorize } from "../../helpers/common";
import { Paginator } from "primereact/paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import LogoutConfirmationModal from "../../components/LogoutModel";
import { Console } from "console";
import { grey, primarytext } from "../../common/primary";
interface DropdownItem {
  id: string;
  value: string;
  type: string;
}

interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

const QRCodeScanner: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [dropdownData, setDropdownData] = useState<Dropdown[]>([]);
  const [editModal, setEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch<any>();
  const { organization } = useSelector((state: any) => state.Login);
  const orgId = params?.id ? params.id : organization;
  const { beaconData, totalElements } = useSelector((state: any) => state.Beacon);
  const [modal, setModal] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const videoRef = useRef(null);
  const [deviceName, setDeviceName] = useState("");
  let [deviceType, setDeviceType] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [uuid, setUuid] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const codeReader = new BrowserMultiFormatReader();
  const [options, setOptions] = useState<any>([]);
  const [scanning, setScanning] = useState(false);
  const orgName = window.localStorage.getItem("OrgName");
  const [searchText, SetsearchText] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [qrReader, setQrReader] = useState<any>('')
  const inputRef = useRef<HTMLInputElement>(null); 

  const handleQrReaderClick = () => {
    setShowTextField(true);
  };
useEffect(() => {
  if (qrReader.legth < 12) return;
  handleQrValueChange()
},[qrReader])
  const handleQrValueChange = () => {
    if (qrReader.length >= 12) {
      // setQrReader(qrReader);
  
      let newDeviceId = '';
      if (qrReader.length < 15) {                       
        const resultId = qrReader.match(/.{2}/g)?.join(':');
        newDeviceId = resultId;
      } else {
        const resultId = qrReader.match(/.{2}/g)?.join(':');
        newDeviceId = resultId.slice(6, 23);
        setDeviceName(qrReader.slice(24, 30));
      }
  
      setDeviceId(newDeviceId);
      handleShow1Close();
      setNewModal(true);
    }
  };
  

  useEffect(() => {
    if (showTextField && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showTextField]);

  useEffect(() => {
    if (scanning) {
      startScanning();
    } else {
      codeReader.reset();
    }

    return () => {
      codeReader.reset();
    };
  }, [scanning]);

  const startScanning = async () => {
    try {
      setScanning(true);
      const videoInputDevices = await codeReader.listVideoInputDevices();
      const selectedDeviceId = videoInputDevices[0].deviceId;
      const constraints = {
        video: { deviceId: selectedDeviceId },
      };
      codeReader.decodeFromVideoDevice(
        selectedDeviceId,
        videoRef.current,
        (result: any, err: any) => {
          if (result) {
            const result1 = result.getText();
            let newDeviceId = "";
            if (result1 !== "" && result1 !== undefined && result1 !== null) {
                if(result1.length < 15){                       
                  const resultId = result1.match(/.{2}/g).join(':');
                  newDeviceId = resultId;                   
                }else{
                  const resultId = result1.match(/.{2}/g).join(':');    
                  newDeviceId = resultId.slice(6,23);                
                setDeviceName(result1.slice(24,30));                         
                }
                setShow(false);
                setScanning(false);
            }
            const scannedOption = options.find((option: any) => option.deviceId === newDeviceId);              
            if (scannedOption === undefined) {        
              setNewModal(true);   
              setDeviceId(newDeviceId);
        const modal = document.getElementById("exampleModal");
        if (modal) {
          modal.classList.add("show");
          modal.style.display = "block";
        }                  
    }else{
      setDeviceId("");     
      toast.error("Given Beacon Device was registered.");
    }
          }
          if (err && err.name === "NotFoundError") {
            console.error("No QR code found in the video feed.");
          }
          if (err) {
            console.error("Error during scanning:", err);
          }
        },
      );
    } catch (error) {
      console.error("Error starting the scanner:", error);
    }
  };

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  };
  const handleShow1Close = () => {
    setShow1(false)
    setQrReader('')
    setShowTextField(false)
  }

  const handleDeviceNameChange = (e: any) => {
    setDeviceName(e.target.value);
  };

  const handleUUIDChange = (e: any) => {
    setUuid(e.target.value);
  };

  const closeModalAndRec = () => {
    setShow(false)
    setScanning(false)
    codeReader.reset()
  }

  const handleQrClick = () => {
    setShow1(false)
    setShow(true);
    setDeviceId("")
    setDeviceName("")
    setScanning(!scanning);
  }
  const handleQrClick1 = () => {
    setShow1(true)
  }

  const postAPI = async () => {
    // let convertedDId = deviceId;
    // convertedDId = convertedDId.replace(/(.{2})/g, "$1:");
    // convertedDId = convertedDId.slice(0, -1);
    const { header1 } = authorize();
    try {
      const res = await axios.post(`${baseURL}/sensor/register`, {
        deviceId,
        deviceName,
        uuid,
        orgId,
        deviceType,
        modelNumber
      },{headers: header1});
      if (res.data.message.code === successCode) {
        handleClose1();
        setNewModal(false)
        setDeviceId("");
        setDeviceName("");
        setUuid("");
        setDeviceType("");
        setModelNumber("");
        getAllBeacon(dispatch, orgId, currentPage, '');
      } else {
        handleClose1();
        setDeviceId("");
        setDeviceName("");
        setUuid("");
        setDeviceType("");
        setModelNumber("");
        alert(res.data.message.description);
      }
    } catch (error) {
      console.warn(error);
      handleClose1();
      setDeviceId("");
      setDeviceName("");
    }
  };

  const ScanModalClose = () => {
    setNewModal(false)
    handleClose1();
    setDeviceId("");
    setDeviceName("");
    setUuid("");
    setDeviceType("");
    setModelNumber("");
  }

  const toggle = () => {
    setModal(!modal);
  }
  let [sensorData, setSensorData] = useState({
    id: "",
    uuid: "",
    deviceId: "",
    deviceName: "",
    BeaconType: "",
    modelNumber: '',
    orgId: orgId,
  });

  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/dropdowns/getByDropdown?dropdown=BeaconType`,{headers: header1});
        if (response.data.message.code === 'MHC - 0200') {
          setDropdownData(response.data.data[0].list);
        } else {
          console.error('Error fetching dropdown data:', response.data.message.description);
        }
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };
    const fetchData = async () => {
      const { header1 } = authorize();
      try {
        const response = await axios.get(`${baseURL}/sensor/getAllByorgId/${orgId}`, { headers: header1 });
        setOptions(response.data.data.content);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    fetchDropdownData();
  }, []);
  const [open, setOpen] = useState(false)

  const handleDelete = (deviceName: any) => {
    setOpen(true);
    setDeviceName(deviceName);
    
}
  const handleClo = () => {
    setOpen(false)
    setDeviceName('');
  }

  const deleteAPI = async (deviceName: any) => {
    // const del = window.confirm(`Do you want to delete this data?  ${deviceName}`);
    // if (del) {
      const { header1 } = authorize();
      try {
        const res = await axios.delete(
          `${baseURL}/sensor/deleteByDeviceName/${deviceName}`,{headers: header1}
        );
        handleClo()
        toast.success("Your data has been deleted successfully.");
        getAllBeacon(dispatch, orgId, currentPage, '');
      } catch (error) {
        console.warn(error);
      // }
    // } else {
    //   toast.error("You declined the delete request.");
    }
  };

  useEffect(() => {
    getAllBeacon(dispatch, orgId, searchText ? 0 : currentPage, searchText ? searchText : '');
  }, [dispatch, searchText]);

  const handleSave = async () => {
    const updateFields = {
      id: sensorData.id,
      uuid: sensorData.uuid,
      deviceId: sensorData.deviceId,
      deviceName: sensorData.deviceName,
      deviceType: sensorData.BeaconType,
      modelNumber: sensorData.modelNumber,
      orgId: orgId,
    };

    setSensorData((prevData) => ({
      ...prevData,
      ...updateFields,
    }));

    try {
      await updatedSensorDetails(
        dispatch,
        sensorData.id,
        updateFields,
        orgId
      );

      setEditModal(false);
      getAllBeacon(dispatch, orgId, currentPage, '')
    } catch (error) {
      console.error("Error updating sensor details:", error);
    }
  };

  const handleClick = (selectSensor: any) => {
    if (selectSensor) {
      setSensorData({
        id: selectSensor?.id,
        uuid: selectSensor?.uuid || "",
        deviceId: selectSensor?.deviceId || "",
        deviceName: selectSensor?.deviceName || "",
        BeaconType: selectSensor?.deviceType || "",
        modelNumber: selectSensor?.modelNumber || "",
        orgId: orgId,
      });
      setEditModal(true);
    } else {
      console.warn("Invalid data", selectSensor);
    }
  };

  const handleSearchChange = (e:any) =>{
    SetsearchText(e.target.value);
  }

  const handleChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSensorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleModelChange = (event: any, newValue: any) => {
      let modelNumber = '';
      if (newValue === "W7") {
        modelNumber = "METTLER WRISTBAND BEACON";
      } else if (newValue === "W8") {
        modelNumber = "METTLER SOS BEACON";
      } else if (newValue === "KG02") {
        modelNumber = "METTLER BEACON GATEWAY";
      } else if (newValue === "WH15") {
        modelNumber = "METTLER HR WRISTBAND BEACON";
      }
      setDeviceType(modelNumber)
      setModelNumber(newValue)
    };
    const handleModelChange1 = (event: any, newValue: any) => {
      let modelNumber = '';
      if (newValue === "W7") {
        modelNumber = "METTLER WRISTBAND BEACON";
      } else if (newValue === "W8") {
        modelNumber = "METTLER SOS BEACON";
      } else if (newValue === "KG02") {
        modelNumber = "METTLER BEACON GATEWAY";
      } else if (newValue === "WH15") {
        modelNumber = "METTLER HR WRISTBAND BEACON";
      }
  
      setSensorData(prevState => ({
        ...prevState,
        BeaconType: modelNumber,
        modelNumber: newValue,
      }));
    };

  const handleDeviceTypeChange = (event: any) => {
    setDeviceType(event.target.value);
  };
  return (
    <div className="container ">
      <div className="row">
        {params?.id ? (
      <div className="col-md-3 d-flex align-items-center gap-1">
          <FaArrowAltCircleLeft style={{cursor:'pointer',fontSize:'25px'}} onClick={() => navigate(-1)} />
          <h5 className="d-flex align-items-center">Beacon/Device List</h5>
        </div>
      ) : (
        <div className="col-md-3 d-flex align-items-center">
      <h5 className="">Beacon/Device List</h5>
    </div>
        )
        }
        {params?.id ? (
        <div className="col-md-2 d-flex justify-content-center align-items-center">
          <h5>{orgName}</h5>
          </div>
        ) : (
        <div className="col-md-2 d-flex align-items-center"></div>
        )
        }
        <div className="col-md-7">
        <div style={{display:"flex",justifyContent:"flex-end",gap:"10px",paddingBottom:"10px",paddingTop:"10px"}}>
              {params.id && (<div >
              <button
                className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                style={{ fontSize: '10px', width: '100px', height: '30px' }}
                onClick={handleQrClick1}
              >
                <FaQrcode
                  style={{ cursor: "pointer", fontSize: "17px" }}
                />{" "}
                Scan
              </button>
            </div>)}
            {params?.id && (<div >
              <button
                className="btn btn-outline-primary floflat-end d-flex gap-2 align-items-center"
                style={{ fontSize: '10px', width: '190px', height: '30px' }}
                onClick={toggle}
              >
                <TbDeviceWatchPlus
                  style={{ cursor: "pointer", fontSize: "20px" }}
                />{" "}
                Register a New Device
              </button>
            </div>)}
            <div >
              <div className="mx-0 search-container d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search..."
                  className="search form-control"
                  value = {searchText}
                  onChange={handleSearchChange}
                  style={{ fontSize: "10px", width: '170px', height: '30px' }}
                />
                <FaSearch className="search-icon mt-1" style={{ fontSize: "10px" }} />
              </div>
            </div>
          </div>
          </div>
      </div>
      <div >
        <table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead style={{ backgroundColor: grey }}>
            <tr >
              <th scope="col" className="text-center" style={{ fontSize: '13px', color: primarytext, backgroundColor: "#dee2e638" }}>S.No</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Device Serial Number</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Device ID</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Unique ID</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Model</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Device Type</th>
              <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Status</th>
              {params.id && (<th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: "#dee2e638" }}>Action</th>)}
            </tr>
          </thead>
          <tbody className="tbody">
            {beaconData.length > 0 ? beaconData.map((item: any, index: any) => (
              <tr key={item.id}>
                <td>{currentPage *10 + index + 1}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={params.id ? () => handleClick(item) : () => {}}>
                  {item.deviceName}
                </td>
                <td>{item.deviceId}</td>
                <td>{item.uuid}</td>
                <td>{item.modelNumber}</td>
                <td>{(item.deviceType) ? item.deviceType : 'NA'}</td>
                <td className="text-center">
                <Tooltip title= { !(item.status) ? "Available" : "Occupied"} arrow>
                  <FontAwesomeIcon
                          icon={faCircle}
                          style={{
                            fontSize: "13px",
                            cursor: "pointer",
                            color: !(item.status) ? '#5FB477' : '#EF6868',
                            display: "inline-block",
                            textAlign: "center",
                          }}
                        />
                        </Tooltip>
                        </td>
                {params.id && (<td className="text-center d-flex justify-content-around align-items-center">
                  <Tooltip title="Edit" arrow>
                    <div onClick={() => handleClick(item)} style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={editimg} /></div>
                    </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <div onClick={() => handleDelete(item.deviceName)} style={{ cursor: "pointer" }}><img style={{ height: "15px", width: "15px" }} src={Trashimg} /></div>
                  </Tooltip>
                </td>)}
              </tr>
            )) : 
            <tr>
              <td colSpan={7} className="text-center">
                <h5>No Beacons found</h5>
              </td>
            </tr>
          }
          </tbody>
        </table>
        <BeaconCreation modal={modal} orgId1={params?.id ? orgId : null} toggle={toggle} currentPage={currentPage}/>

      </div>
      <div className="pagination-container" >
        <div className="d-flex justify-content-center">
        <Paginator
            first={currentPage * 10}
            rows={10}
            totalRecords={totalElements}
            onPageChange={(e: any) => {   
              setCurrentPage(e.page)
              getAllBeacon(dispatch, orgId, e.page, searchText ? searchText : '')
            }}
            currentPageReportTemplate={`Page ${currentPage} of ${totalElements/10}`}
          />         
        </div>
      </div>
      <Modal isOpen={editModal} centered size="lg" style={{ width: '600px' }}>
        <div className="d-flex justify-content-start align-items-center">
          <div className="row">
            <div className="col-md-12">
              <ModalHeader toggle={() => setEditModal(false)}>
            Device Details
          </ModalHeader>
        </div>
        <ModalBody>
          <div className="d-flex align-items-center justify-content-center vh-90">
            <div className="row w-100">
              <div className="container col-md-12 ">
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                  <div className="col-md-12 mb-2">
                    <TextField id="outlined-basic-1" label="Unique Id(UUID)" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.uuid} name="uuid" />
                  </div>
                </div>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                  <div className="col-md-6 mb-2">
                    <TextField id="outlined-basic-1" label="Mac Address" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.deviceId} name="deviceId" />
                  </div>
                  <div className="col-md-6 mb-2">
                  <TextField id="outlined-basic-1" label="Serial Number" variant="outlined" fullWidth onChange={handleChange1} value={sensorData.deviceName} name="deviceName" />
                  </div>
                </div>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
      <div className="col-md-6 mb-2">
        <Autocomplete
          id='model'
          options={dropdownData?.map((item: any) => item.value)}
          value={sensorData.modelNumber}
          onChange={handleModelChange1}
          renderInput={(params) => <TextField {...params} label="Model" variant="outlined" />}
        />
      </div>
      <div className="col-md-6 mb-2">
        <TextField
          id="outlined-basic-1"
          label="Device Type"
          variant="outlined"
          fullWidth
          value={sensorData.BeaconType ? sensorData.BeaconType : 'N/A'}
          name="modelNumber"
        />
      </div>
    </div>
              </div>
            </div>
          </div>
        </ModalBody>
            </div>
          </div>
          
        <ModalFooter className="">
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setEditModal(false)}></Button>
            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSave}></Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal centered isOpen={show} toggle={handleClose1}>
        <ModalHeader toggle={handleClose1}>Scanning</ModalHeader>
        <ModalBody>
          <video ref={videoRef} style={{ display: scanning ? "block" : "none", width: '100%', height: '400px' }} />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleClose1}></Button>
            <Button label={scanning ? "Stop Scanning" : "Start Scanning"} style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={closeModalAndRec}></Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal centered isOpen={show1} toggle={handleShow1Close} >
      <ModalHeader toggle={handleShow1Close}>Select the scanning Option</ModalHeader>
      <ModalBody style={{display:'flex',flexDirection:'column' }}>
        <div style={{ display:'flex', justifyContent:'center'}}>
        <Button 
          label="Start Scan"
          style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold', marginRight: 10 }} 
          onClick={handleQrClick}
        >
        </Button>
        <Button 
          label="QR Reader"
          style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} 
          onClick={handleQrReaderClick}
        >
        </Button>
        </div>
        {showTextField && (
          <div style={{marginTop:10}}>
            <TextField
                      id="qrInput"
                      label="Scan a QR using QR reader"
                      variant="outlined"
                      fullWidth
                      inputRef={inputRef}
                      value={qrReader}
                      onChange={(e: any) => setQrReader(e.target.value)}
                    />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex gap-3 justify-content-center"></div>
      </ModalFooter>
    </Modal>
      <Modal isOpen={newModal} centered size="lg" style={{ width: '600px' }}>
        <div className="d-flex justify-content-start align-items-center">
          <div className="row">
            <div className="col-md-12">
              <ModalHeader toggle={() => setNewModal(false)}>
            Add Device
          </ModalHeader>
        </div>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <div className="row w-100">
              <div className="container col-md-12 ">
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                  <div className="col-md-12 mb-2">
                    <TextField
                      id="outlined-basic-1"
                      label="Unique Id (UUID)"
                      variant="outlined"
                      fullWidth
                      onChange={handleUUIDChange}
                      value={uuid}
                    />
                  </div>
                </div>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
                  <div className="col-md-6 mb-2">
                    <TextField
                      id="outlined-basic-2"
                      label="MAC Address"
                      variant="outlined"
                      fullWidth
                      value={deviceId}                         
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                  <TextField
                      id="outlined-basic-4"
                      label="Serial Number"
                      variant="outlined"
                      fullWidth
                      onChange={handleDeviceNameChange}
                      value={deviceName}
                    />                   
                  </div>
                </div>
                <div className="row w-100" style={{ alignItems: "center", justifyContent: "center", marginTop: '10px' }}>
      <div className="col-md-6 mb-2">
        <Autocomplete
          id="Model"
          options={dropdownData?.map((item: any) => item.value)}
          value={modelNumber}
          onChange={handleModelChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Model"
              fullWidth
            />
          )}
        />
      </div>
      <div className="col-md-6 mb-2">
        <TextField
          id="outlined-basic-3"
          label="Device Type"
          variant="outlined"
          fullWidth
          value={deviceType}
          onChange={handleDeviceTypeChange}
        />
      </div>
    </div>
              </div>
            </div>
          </div>
        </ModalBody>
            </div>
          </div>
          
        <ModalFooter className="">
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={ScanModalClose}></Button>
            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={postAPI}></Button>
          </div>
        </ModalFooter>
      </Modal>
    <LogoutConfirmationModal
        open={open}
        handleConfirm={() => deleteAPI(deviceName)}
        handleClose={() => handleClo()}
        message={`Do you want to delete this data?  ${deviceName}`}
      />
    </div>
  );
};

export default QRCodeScanner;