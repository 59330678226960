import axios from "axios"
import { endLoading, getPSConfigSuccess, getRNInchargeListSuccess, getSocialWorkerListSuceess, startLoading } from "./reducer"
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from "../../helpers/common"

export const getPSConfigByDate = async (dispatch: any, date: any, organization: any) => {
    dispatch(startLoading())
    const { header1 } = authorize();
    try {
        const response = await axios.get(`${baseURL}/PSConfig/getByDate/${date}/${organization}`,{headers: header1})
        if (response.data.message.code === successCode) {
            dispatch(getPSConfigSuccess(response.data.data.shift))
        } else {
            dispatch(endLoading())
            dispatch(getPSConfigSuccess([]))

        }
    } catch (error) {
        dispatch(endLoading())
        dispatch(getPSConfigSuccess([]))
    }
}

export const getAllRNIncharge = async (dispatch: any, role: string, orgId: string) => {
    dispatch(startLoading())
    const { header1 } = authorize();
    try {
        const response = await axios.get(`${baseURL}/staff/role/${role}/${orgId}`,{headers: header1})
        if (response.data.message.code === successCode) {
            dispatch(endLoading())
        dispatch(getRNInchargeListSuccess(response.data.data))
        } else {
        dispatch(endLoading())
        dispatch(getRNInchargeListSuccess([]))
        }
    } catch (error) {
        dispatch(endLoading())
        dispatch(getRNInchargeListSuccess([]))
    }
}

export const getAllSocialWorkers = async (dispatch: any, role: string, orgId: string) => {
    dispatch(startLoading())
    const { header1 } = authorize();
    try {
        const response = await axios.get(`${baseURL}/staff/role/${role}/${orgId}`,{headers: header1})
        if (response.data.message.code === successCode) {
            dispatch(endLoading())
        dispatch(getSocialWorkerListSuceess(response.data.data))
        } else {
            dispatch(endLoading())
            dispatch(getSocialWorkerListSuceess([]))
        }
    } catch (error) {
        dispatch(endLoading())
        dispatch(getSocialWorkerListSuceess([]))
    }
}