import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

interface LogoutConfirmationModalProps {
    open: boolean;
    handleConfirm: () => void;
    handleClose: () => void;
    message: string;
}

const LogoutConfirmationModal: React.FC<LogoutConfirmationModalProps> = ({ open, handleConfirm, handleClose, message }) => {

    return (
        <Modal
        isOpen={open}
        toggle={handleClose}
        centered
        style={{ fontFamily: "calibri", fontSize: "20px" }}
    >
            <ModalHeader>
                Mettler Health Care
            </ModalHeader>
            <ModalBody style={{ display: "flex", gap: "20%", flexDirection: 'column', alignItems: 'center' }}>
                <div>{message}</div>
                <div style={{ display: "flex", gap: "20%" }}>
                    <Button color="primary" onClick={handleConfirm}>
                        Yes
                    </Button>
                    <Button onClick={handleClose}>
                        No
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default LogoutConfirmationModal;
