import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  dropdownData: [],
  isOpen: false,
  errorMsg: "",
  isSuccess: false,
};

const DropdownSlice = createSlice({
  name: "dropdownData",
  initialState,
  reducers: {
    isLoading(state) {
      state.loading = true;
    },

    setIsLoadingFalse(state) {
      state.loading = false;
    },
    setErrorMessage(state, action) {
      state.loading = false;
      state.isOpen = true;
      state.errorMsg = action.payload;
    },

    getDropdownSuccess(state, action) {
      state.loading = false;
      state.dropdownData = action.payload;
    },
  },
});

export const {
  isLoading,
  setIsLoadingFalse,
  setErrorMessage,
  getDropdownSuccess,
} = DropdownSlice.actions;

export default DropdownSlice.reducer;