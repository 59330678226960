import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import "./staff.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllStaff, deleteStaffDetails, updateStaffDetails } from "../../slices/thunk";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ModalBody, ModalHeader, Table } from "reactstrap";
import Loader from "../../components/loader/Loader";
import StaffCreationForm from "./staffCreation";
import { Modal, ModalFooter } from "reactstrap";
import { baseURL } from "../../configuration/url";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from 'axios';
import * as Constants from "../Constants/Constant";
import { Button } from "primereact/button";
import { authorize } from "../../helpers/common";
import { Paginator } from "primereact/paginator";
import { setAllSetupOptions } from "../../slices/staff/reducer";
import { primarybg, secondarybg } from "../../common/primary";
interface DropdownItem {
  id: string;
  value: string;
  type: string;
}
interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

const Staff: React.FC = () => {
  const [selectStaffId, setSelectStaffId] = useState<string | null>(null);
  let [cityDropDown, setCityDropDown] = useState(new Array<any>());
  const dispatch = useDispatch<any>();
  const { staffData, loading, totalElements, genderList, specialityList, rolesList } = useSelector((state: any) => state.Staff);
  const { organization } = useSelector((state: any) => state.Login);
  const [currentPage, setCurrentPage] = useState(0);
  let [highlightfirstname, setHighlight] = useState(false);
  let [highlightlastname, sethighlightlastname] = useState(false);
  let [highlightssn, sethighlightssn] = useState(false);
  let [highlightemail, sethighlightemail] = useState(false);
  let [highlightzipcode, sethighlightzipcode] = useState(false);
  let [highlightdate, sethighlightdate] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    getAllStaff(dispatch, organization, searchTerm ? 0 : currentPage, searchTerm ? searchTerm : '');
  }, [dispatch, organization, searchTerm]);
  
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const [editModal, setEditModal] = useState(false);
  let [formData, setFormData] = useState({
    firstName: "",
    middleName: " ",
    lastName: " ",
    dateofBirth: " ",
    ssn: " ",
    npi: " ",
    addressLine1: " ",
    addressLine2: " ",
    city: " ",
    state: " ",
    zip: " ",
    mobilePhone: " ",
    email: " ",
    gender: " ",
    country: " ",
    roles: " ",
    speciality: " ",
    startDate: " ",
    active: " ",
    userType: "",
  });

  const handleSelectChange = (fieldName: string, value: any) => {
    setFormData({ ...formData, [fieldName]: value });
  };
  const [loading1, setLoading] = useState(false);
  const handlePostalCodeChange = async (e: any) => {
    formData.zip = e.target.value;
    setFormData({ ...formData });
    sethighlightzipcode(false);
    if (e.target.value.length === 5) {
      setLoading(true);
      try {
        const response = await axios.get(`https://app.zipcodebase.com/api/v1/search?codes=${e.target.value}&country=US&${Constants.apiKey}`);
        formData.state = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].state : "";
        formData.country = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].country_code : "";
        formData.city = response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]][0].city : "";
        setCityDropDown(response.data.query.codes !== null && response.data.query.codes !== undefined ? response.data.results[response.data.query.codes[0]].map((k: any) => k.city_en) : [])
      } catch (error) {
        console.error('Error fetching city:', error);
      } finally {
        setLoading(false);
      }
    } else {
      formData.state = "";
      formData.country = "";
      formData.city = "";
      setLoading(false);
    }
    setFormData({ ...formData });
  };
  const handleAddress = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const highlight = () => {
    let highlighted = false; // Variable to track if any fields are highlighted

    // Check organizationName field
    if (!formData.firstName.trim()) {
      setHighlight(true);
      toast.error("Please Enter First Name");
      highlighted = true; // Set highlighted to true if any field is highlighted
    } else {
      setHighlight(false); // Clear highlighting if field is not empty
    }

    // Check email field
    if (!formData.lastName.trim()) {
      sethighlightlastname(true);
      toast.error("Please Enter LastName");
      highlighted = true;
    } else {
      sethighlightlastname(false);
    }

    // Check npi field
    if (!formData.dateofBirth.trim()) {
      sethighlightdate(true);
      toast.error("Please Enter birthDate");
      highlighted = true;
    } else {
      sethighlightdate(false);
    }

    // Check tin field
    if (!formData.ssn.trim()) {
      sethighlightssn(true);
      toast.error("Please Enter ssn");
      highlighted = true;
    } else {
      sethighlightssn(false);
    }

    // Check mobileNumber field
    if (!formData.email.trim()) {
      sethighlightemail(true);
      toast.error("Please Enter email");
      highlighted = true;
    } else {
      sethighlightemail(false);
    }

    // Check duration field
    if (!formData.zip.trim()) {
      sethighlightzipcode(true);
      toast.error("Please Enter Postalcode");
      highlighted = true;
    } else {
      sethighlightzipcode(false);
    }
    return highlighted; // Return true if any field is highlighted, false otherwise
  };
  const handleinputchange = (event: any) => {
    if (event.target.id === 'firstname') {
      formData.firstName = event.target.value;
      setHighlight(false);
    } else if (event.target.id === 'middlename') {
      formData.middleName = event.target.value;
    } else if (event.target.id === 'lastname') {
      formData.lastName = event.target.value;
      sethighlightlastname(false);
    } else if (event.target.id === 'dateofBirth') {
      formData.dateofBirth = event.target.value;
      sethighlightdate(false);
    } else if (event.target.id === 'email') {
      formData.email = event.target.value;
      sethighlightemail(false);
    } else if (event.target.id === 'ssn') {
      formData.ssn = event.target.value;
      sethighlightssn(false);
    } else if (event.target.id === 'addressLine1') {
      formData.addressLine1 = event.target.value;
    } else if (event.target.id === 'addressline2') {
      formData.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formData.city = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'mobilePhone') {
      formData.mobilePhone = event.target.value;
    } else if (event.target.id === 'npi') {
      formData.npi = event.target.value;
    } else if (event.target.id === 'speciality') {
      formData.speciality = event.target.value;
    } else if (event.target.id === 'roles') {
      formData.roles = event.target.value;
    } else if (event.target.id === 'userType') {
      formData.userType = event.target.value;
    }
    setFormData({ ...formData });
  }
  useEffect(() => {
    const fetchDropdownData = async () => {
      const { header1 } = authorize()
      const apiDomain = `${baseURL}/dropdowns/getByDropdown?dropdown=`
      let errorArray: string[]
      try {
        const urls = [
          apiDomain + 'gender',
          apiDomain + 'speciality',
          apiDomain + 'roles'
        ]
        Promise.all(urls.map((url) => axios.get(url, { headers: header1 }))).then((data) => {
          const optionSelects = {
            Gender: data[0].data.data[0].list,
            Speciality: data[1].data.data[0].list,
            Roles: data[2].data.data[0].list
          }
          dispatch(setAllSetupOptions(optionSelects))
        }).catch((err: any) => {
          toast.error(errorArray[0])
        })
      } catch (error) {
        console.warn(error, 'checking the error')
      }
    }

    fetchDropdownData();
  }, []);
  const handleDelete = async (username: string) => {
    const confirmDelete = window.confirm("Are You Sure Do You Want To Delete?");
    if (confirmDelete) {
      try {
        await dispatch(deleteStaffDetails(username, organization));
      } catch (error) {
        console.warn("Failed to delete organization");
      }
    }
  };

  const handleSaveChanges = () => {
    const isHighlighted = highlight();
    if (!isHighlighted) {
      if (!selectStaffId) {
        return;
      } else if (formData.zip.length > 0 && formData.city === "" && formData.state === "") {
        formData.city = "";
        formData.state = "";
        formData.country = "";
        toast.error("Please Enter Valid Zip Code");
        return;
      }
      const updatedStaffFields = {
        id: selectStaffId,
        name: [
          {
            use: formData.middleName,
            given: formData.firstName,
            family: formData.lastName,
          },
        ],
        gender: formData.gender,
        email: formData.email,
        role: formData.roles,
        organization,
        startDate: formData.startDate,
        speciality: [formData.speciality],
        dateofBirth: formData.dateofBirth,
        ssn: formData.ssn,
        npi: formData.npi,
        userType: formData.userType,
        contact: [
          {
            address: [
              {
                addressLine1: formData.addressLine1,
                addressLine2: formData.addressLine2,
                city: formData.city,
                state: formData.state,
                country: formData.country,
                zip: formData.zip,
              },
            ],
            mobilePhone: formData.mobilePhone,
          },
        ],
        active: formData.active,
      };
      dispatch(
        updateStaffDetails(
          selectStaffId,
          updatedStaffFields,
          organization,
          setEditModal,
          currentPage
        )
      );
      setEditModal(false);
    };
  };
  const [selectedStaffName, setSelectedStaffName] = useState('');
  const { firstName, middleName, lastName } = formData;
  const handleClick = (selectStaff: any) => {
    if (selectStaff) {
      const fullName = `${selectStaff.name[0].given} ${selectStaff.name[0].family}`;
      setSelectedStaffName(fullName);
      const staffId = selectStaff.id || "";
      setSelectStaffId(staffId);
      const name = selectStaff.name?.[0] || {};
      const contact = selectStaff.contact?.[0]?.address?.[0] || {};
      setFormData({
        firstName: name.given || "",
        middleName: name.use || "",
        lastName: name.family || "",
        dateofBirth: selectStaff.dateofBirth || "",
        ssn: selectStaff.ssn || "",
        npi: selectStaff.npi || "",
        addressLine1: contact.addressLine1 || "",
        addressLine2: contact.addressLine2 || "",
        city: contact.city || "",
        state: contact.state || "",
        zip: contact.zip || "",
        mobilePhone: selectStaff.contact?.[0]?.mobilePhone || "",
        email: selectStaff.email || "",
        gender: selectStaff.gender || "",
        country: contact.country || "",
        roles: selectStaff.role || "",
        speciality: selectStaff.speciality?.[0] || "",
        startDate: selectStaff.startDate || "",
        active: selectStaff.active || " ",
        userType: selectStaff.userType || " ",
      });
      setEditModal(true);
    } else {
      console.error("Invalid staff data:", selectStaff);
    }
  };

  return (
    <div className="container m5 p3" style={{ width: '90%', paddingTop: "10px" }}>
      {loading && <Loader />}
      <div className="row">
        <div className="col-md-8 d-flex align-items-center">
          <h5>Staff List</h5>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center mb-1 gap-2">
        <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: '#1F489F', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
            <i style={{ fontSize: "36px", fontWeight: "lighter", color: "white" }} onClick={toggle} className="material-icons">add</i>
          </div>
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              className="search form-control"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="search-icon mt-1" />
          </div>
          
        </div>
        <Table className="table table-bordered" style={{ fontSize: '13px' }}>
          <thead>
            <tr>
              <th scope="col" style={{width:'13px'}} className="text-center table-data">S.No</th>
              <th scope="col" className="text-center table-data">Staff Name</th>
              <th scope="col" className="text-center table-data">Role</th>
              <th scope="col" className="text-center table-data">Job Title</th>
              <th scope="col" className="text-center table-data">Action</th>
            </tr>
          </thead>
          <tbody>
            {staffData.length > 0 ? staffData?.map((staff: any, index: number) => (
              <tr key={index}>
                <td className="text-center">{currentPage * 10 + index + 1}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClick(staff)}
                >
                  {staff.name[0].given} {staff.name[0].family}
                </td>
                <td>{staff.userType}</td>
                <td>{staff.role}</td>
                <td className="text-center d-flex justify-content-around align-items-center">
                  <Tooltip title="Edit" arrow>
                    <FontAwesomeIcon
                      icon={faPencil}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(staff)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-danger"
                      onClick={() => handleDelete(staff.username)}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </td>
              </tr>
            )) : 
            <tr>
              <td colSpan={7} className="text-center">
                <h5>No Staff found</h5>
              </td>
            </tr>
            }
          </tbody>
        </Table>
      </div>
      <StaffCreationForm modal={modal} toggle={toggle} currentPage={currentPage} />
      <div className="row">
        <div className="col-md-3">
        </div>
        <div className="pagination-container" >
          <div className="d-flex justify-content-center">
            <Paginator
              first={currentPage * 10}
              rows={10}
              totalRecords={totalElements}
              onPageChange={(e: any) => {
                setCurrentPage(e.page)
                getAllStaff(dispatch, organization, e.page, searchTerm ? searchTerm : '')
              }}
              currentPageReportTemplate={`Page ${currentPage} of ${totalElements / 10}`}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={editModal}
        // toggle={() => setEditModal(false)}
        centered
        size="lg"
      ><div className="d-flex align-items-center justify-content-center vh-90">
          <div className="row">
            <div className="container col-md-12">
              <ModalHeader toggle={() => setEditModal(false)}>
                {selectedStaffName}
              </ModalHeader>
              <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                <div className="row w-100 ">
                  <div className="col-md-4">
                    <TextField
                      error={highlightfirstname}
                      id="firstname"
                      name="firstName"
                      label="First Name"
                      placeholder="Enter First Name"
                      value={formData.firstName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="middlename"
                      name="middleName"
                      label="Middle Name"
                      placeholder="Enter Middle Name"
                      value={formData.middleName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      error={highlightlastname}
                      id="lastname"
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter LastName"
                      value={formData.lastName}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mt-3">
                    <Autocomplete
                      id='gender'
                      options={genderList?.map((item: any) => item.value)}
                      value={formData.gender}
                      onChange={(e, v) => handleSelectChange('gender', v)}
                      renderInput={(params) => (
                        <TextField {...params} label='Gender' variant="outlined" />
                      )}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      error={highlightdate}
                      id="dateofBirth"
                      name="dateofBirth"
                      label="Date Of Birth"
                      placeholder="Enter DateOfBirth"
                      value={formData.dateofBirth}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      error={highlightssn}
                      id="ssn"
                      name="ssn"
                      label="SSN"
                      placeholder="Enter SSN"
                      value={formData.ssn}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      inputProps={{
                        maxLength: 9,
                        pattern: '[0-9]*',
                        inputMode: 'numeric'
                      }}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <TextField
                      error={highlightemail}
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      id="mobilePhone"
                      name="mobilePhone"
                      label="Phone Number"
                      placeholder="Enter Phone Number"
                      value={formData.mobilePhone}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <TextField
                      id="npi"
                      name="npi"
                      label="NPI#"
                      placeholder="Enter NPI"
                      value={formData.npi}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                    <div className='col-md-6 mt-3 '>
                  <FormControl fullWidth>
                    <InputLabel >Role</InputLabel>
                    <Select
                      value={formData.userType}
                      label="Role"
                      onChange={(event) =>  setFormData({
                        ...formData,
                        userType: event.target.value,
                      })}
                    >
                      <MenuItem value={'Admin'}>Admin</MenuItem>
                      <MenuItem value={'Staff'}>Staff</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                </div>
                <div className="row w-100 mt-2">
                  <div className="col-md-6">
                    <Autocomplete
                      id='speciality'
                      options={specialityList?.map((item: any) => item.value)}
                      value={formData.speciality}
                      onChange={(e, v) => handleSelectChange('speciality', v)}
                      renderInput={(params) => (
                        <TextField {...params} label='Speciality' variant="outlined" />
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <Autocomplete
                      id='roles'
                      options={rolesList?.map((item: any) => item.value)}
                      value={formData.roles}
                      onChange={(e, v) => handleSelectChange('roles', v)}
                      renderInput={(params) => (
                        <TextField {...params} label='Job Title' variant="outlined" />
                      )}
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4 mb-2">
                    <TextField
                      id="addressLine1"
                      name="addressLine1"
                      label="Address Line 1"
                      placeholder="Enter Address"
                      value={formData.addressLine1}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mb-2">
                    <TextField
                      id="addressLine2"
                      name="addressLine2"
                      label="Address Line 2"
                      placeholder="Enter Address"
                      value={formData.addressLine2}
                      onChange={handleAddress}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 mt-3 mb-2">
                    <TextField
                      error={highlightzipcode}
                      id="zipcode"
                      label="Zip/Postal Code"
                      variant="outlined"
                      fullWidth
                      value={formData.zip}
                      onChange={handlePostalCodeChange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                      inputProps={{
                        maxLength: 5,
                        pattern: "\\d{5}",
                      }}
                    />
                    {loading1 && <CircularProgress size={'25px'} />}
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-4">
                    {cityDropDown !== null && cityDropDown.length === 1 ?
                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        fullWidth
                        value={formData.city}
                        onChange={handleinputchange}
                        InputLabelProps={{
                          shrink:true,
                      }}
                      /> :
                      <Autocomplete
                        id="city"
                        options={cityDropDown}
                        value={formData.city}
                        getOptionLabel={(option) => option}
                        onChange={(e, v) => { formData.city = v; setFormData({ ...formData }); }}
                        sx={{ width: "100%" }}
                        size="medium"
                        renderInput={params =>
                          <TextField
                            name=""
                            {...params}
                            variant="outlined"
                            label="City"
                            placeholder=""
                            margin="none"
                            size="medium"
                            fullWidth
                          />
                        }
                      />}
                  </div>
                  <div className="col-md-4 dropup">
                    <TextField
                      id="state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      value={formData.state}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="country"
                      label="Country"
                      variant="outlined"
                      fullWidth
                      value={formData.country}
                      onChange={handleinputchange}
                      InputLabelProps={{
                        shrink:true,
                    }}
                    />
                  </div>
                </div>
              </ModalBody>
            </div>
          </div>
        </div>
        <ModalFooter className="">
          <div className="d-flex gap-3 justify-content-center">
            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => setEditModal(false)}></Button>
            <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={handleSaveChanges}></Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Staff;