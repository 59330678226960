import axios from 'axios'
import { isLoading, setErrorMessage, setIsLoadingFalse, getBedAssignSuccess } from "./reducer"
import { getAllBed } from '../thunk';
import { toast } from 'react-toastify';
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';

export const getAllBedAssign = async (dispatch: any, orgId: string) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/Q15Bed/getByAvailable/${orgId}`,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(setIsLoadingFalse());
      dispatch(getBedAssignSuccess(response.data.data));
    } else {
      dispatch(setIsLoadingFalse());
      dispatch(setErrorMessage(response.data.message.description));
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    toast.error("Error: something went wrong.")
  }
};

export const getAllBedAssignByOrg = async (dispatch: any, orgId: string) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.get(`${baseURL}/Q15Bed/getByOrg/${orgId}`,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(getBedAssignSuccess(response.data.data));
      dispatch(setIsLoadingFalse());
    } else {
      dispatch(setErrorMessage(response.data.message.description));
      toast.error(response.data.message.description)
      dispatch(setIsLoadingFalse());
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.error('API error:', error);
  }
};

export const deleteBedAssignDetails = (id:string,org: string) => async (dispatch: any) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const response = await axios.delete(`${baseURL}/Q15Bed/delete/${id}`,{headers: header1});
    if (response.data.message.code === successCode) {
      dispatch(setIsLoadingFalse());
      getAllBedAssign(dispatch,org)
      getAllBed(dispatch,org)
      toast.success(response.data.message.description)
        } else {
          dispatch(setIsLoadingFalse());
      dispatch(setErrorMessage(response.data));
      toast.error(response.data.message.description)
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    dispatch(setIsLoadingFalse());
    toast.error("Error: something went wrong.")
  }
};