import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { Button } from 'primereact/button';
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";

interface FormData {
  id: string;
  roomNo: string;
  bedNo: string;
  orgId: string,
  available:boolean,
}
const BedCreation = () => {
  const { organization } = useSelector((state: any) => state.Login);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    id: "",
    roomNo: "",
    bedNo: "",
    orgId:organization,
    available:true,
  });

  const handleSave = async () => {
    if (!formData.roomNo || !formData.bedNo) {
    alert("Please fill All The Fields");
    return;
  }
    const requestBody = {
      id: "",
      roomNo: formData.roomNo,
      bedNo: formData.bedNo,
      orgId:formData.orgId,
      available:formData.available
    };
    const { header1 } = authorize();
    try {
      const response = await axios.post(
        `${baseURL}/Q15Bed/create`,
        requestBody ,{headers: header1}
      );
      if (
        response.data.message &&
        response.data.message.code === successCode
      ) 
      {
        navigate(`/management/bed-table`); 
      } else {
        console.warn("error:", response.data.message);
      }
    } catch (error) {
      alert("Room No and Bed No Already Exists");
    }
  };
  return (
    <div className='row w-100' style={{marginTop:'60px'}}>
    <div className='col-md-2'></div>
    <div className='col-md-8'>
    <h2 className='mb-2 text-center'>Bed Assign Details</h2>
    <hr></hr>
    <div className="row w-100" style={{marginTop:'40px'}}>
          <div className='col-md-6 mb-2 mx-auto'>
            <TextField id="outlined-basic-1" label="RoomNo" variant="outlined" fullWidth  onChange={(e) => setFormData({ ...formData, roomNo: e.target.value })} className="text-center"/>
          </div>
          </div>
          <div className="row w-100 " style={{marginTop:'20px'}}>
          <div className='col-md-6 mb-2 mx-auto' >
            <TextField id="outlined-basic-2" label="BedNo" variant="outlined" fullWidth onChange={(e) => setFormData({ ...formData, bedNo: e.target.value })} />
          </div>
        </div>
        
      <div className="d-flex gap-3 justify-content-end mt-4">
          <Button label="Cancel" onClick={() => { navigate('/management/bed-table') }} severity="secondary" style={{ color: '#000', backgroundColor: '#fff', border: '2px solid #0f3995' }} />
          <Button label="Save" style={{ backgroundColor: '#0f3995' }} onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};

export default BedCreation;