import { combineReducers } from 'redux';

interface Patient {
    date: Date;
    endTime: string;
    id: string;
    oneToOneObserver: string;
    organization: string;
    patientId: string;
    patientIncharge: string;
    prescribedby: string;
    startTime: string;
}

interface PatientState {
    formValues: [];
}

const initialState: PatientState = {
    formValues: [],
};

const patientReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_FORM_VALUES':
            return {
                ...state,
                formValues: action.payload
            };
        default:
            return state;
    }
};

export default combineReducers({
    patient: patientReducer
});
