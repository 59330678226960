import axios from 'axios'
import { isLoading, setErrorMessage, setIsLoadingFalse, getBeaconSuccess, getTotalElementsSuccess } from "./reducer"
import { toast } from 'react-toastify';
import { baseURL, successCode } from '../../configuration/url'
import { authorize } from '../../helpers/common';

export const getAllBeacon = async (dispatch: any, organization: string, page: number, search: any) => {
  dispatch(isLoading());
  const { header1 } = authorize();
  try {
    const res = await axios.get(
      `${baseURL}/sensor/getAllByorgId/${organization}?page=${page}&search=${search}` ,{headers: header1}
    );
    if(res.data.message.code === successCode){
      dispatch(setIsLoadingFalse());
      dispatch(getBeaconSuccess(res.data.data.content))
      dispatch(getTotalElementsSuccess(res.data.data.totalElements))
    }
  } catch (error) {
    dispatch(setIsLoadingFalse());
    console.warn(error);
  }
};

  export const updatedSensorDetails = async (dispatch: any, id: string, data: any,org:string) => {
    dispatch(isLoading());
    const { header1 } = authorize();
    try {
      const response = await axios.put(`${baseURL}/sensor/updateSensorTableByDeviceName/${id}`, data ,{headers: header1});
      if (response.data.message.code === successCode) {
        dispatch(setIsLoadingFalse());
        toast.success(response.data.message.description)
        getAllBeacon(dispatch,org,0, '');
      } else {
      dispatch(setIsLoadingFalse());
        dispatch(setErrorMessage(response.data.message.description));
        toast.error(response.data.message.description)
      }
    } catch (error) {
      dispatch(setIsLoadingFalse());
      toast.error("Error: something went wrong.")
    }
  };
  