import React, { useState, useEffect ,useRef} from "react";
import Image5 from '../../assets/images/image5.png';
import mettler from '../../assets/images/mettler.jpg';
import { TextField } from "@mui/material";
import { Button } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../configuration/url";
import { authorize } from "../../helpers/common";

interface Data {
  email: string;
  otp: string;
}

const VerifyOtp = () => {
  const [data, setData] = useState<Data>({
    email: "",
    otp: "",
  });
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    const savedEmail = localStorage.getItem('savedEmail');
    if (savedEmail) {
      setData((prevData) => ({ ...prevData, email: savedEmail }));
    } else {
      console.warn('No Mail Found in Local Storage');
    }
  }, []);

  const handleInputChange = (index: number, value: string) => {
    const updatedOtp = data.otp.substring(0, index) + value + data.otp.substring(index + 1);
    setData({ ...data, otp: updatedOtp });
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1]?.focus();
    } else if (!value && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleRequest = async () => {
    const { header1 } = authorize();
    try {
      const response = await axios.post(`${baseURL}/user/verify-otp`, data,{headers: header1});
      alert(response.data.message.description);
      if (response.data.message && response.data.message.code === 'MHC - 0200') {
        navigate('/change-password');
      }
    } catch (error) {
      console.warn('Error:', error);
    }
  };

  return (
    <div className="row passcode-section p-0 m-0" >
      <div className="col-md-6" style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', marginLeft: '-6px', height: '101%' }}>
      <img src={Image5} className="img-fluid"
            style={{ objectFit: "cover", height: '100vh' }} alt="Image"></img>
      </div>
      <div className="col-md-6 d-flex flex-column align-items-md-center justify-content-md-center">
      <div className="d-flex justify-content-center mb-2">
          <img className="p-0 "
          src={mettler}
          style={{ height: "-webkit-fill-available"}}
          alt="Image"
        ></img>
        </div>
        <form className="rounded col-md-8" style={{ padding: '50px' }} >
          <div className="d-flex flex-column gap-4">
            <h4 className="text-center">OTP Verification</h4>
            <div className="d-flex gap-3" >
              {[...Array(6)].map((_, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  value={data.otp[index] || ""}
                  type="text"
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  inputProps={{ maxLength: 1 }}
                  style={{ flex: 1,border:'1px solid #0f3995',borderRadius:'8px'}}
                  inputRef={(ref) => (inputRefs.current[index] = ref)}
                />
              ))}
            </div>
            <Button color="info" style={{ fontSize: '20px' }} onClick={handleRequest}>
              Verify OTP
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;