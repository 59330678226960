import { Avatar, IconButton } from '@mui/material'
import React from 'react'
import { FaBed, FaDoorOpen, FaHeartbeat } from 'react-icons/fa'
import { grey } from '../../common/primary';

interface CardProps {
    patientName: string;
    room: string;
    bed: string;
    heartRate: string;
}

const Card: React.FC<CardProps> = ({ patientName, room, bed, heartRate }) => {
    return (
        <div className='col-sm-3 d-flex align-items-center justify-content-center flex-column w-100'>
            <div className='row d-flex align-items-center flex-column' style={{ width: '80%', backgroundColor: '#F3F6FF', height: '35%', borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                    <div style={{ marginRight: '5px' }}>
                        <Avatar sx={{ width: 32, height: 32, backgroundColor: '#9F9FA2' }}>
                        </Avatar>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ margin: '0', marginBottom: '0px', fontSize: '12px' }}>{patientName}</p>
                        <p style={{ margin: '0', fontSize: '12px' }}>#MH4801</p>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-around' style={{ width: '80%', height: '50%', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', backgroundColor: parseInt(heartRate) < 60 ? '#FBF3E3' : parseInt(heartRate) > 100 ? '#FFECEC' : '#fff' }}>
                <div className='col-md-4 p-0' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                        <IconButton>
                            <FaDoorOpen size={'20px'} />
                        </IconButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '9px', marginBottom: '5px' }}>
                        <p style={{ margin: '0', marginBottom: '-2px' }}>{room}</p>
                        <p style={{ margin: '0' }}>Room No</p>
                    </div>
                </div>
                <div className='col-md-4 p-0' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                        <IconButton>
                            <FaBed size={'20px'} />
                        </IconButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '9px', marginBottom: '5px' }}>
                        <p style={{ margin: '0', marginBottom: '-2px' }}>{bed}</p>
                        <p style={{ margin: '0' }}>Bed No</p>
                    </div>
                </div>
                <div className='col-md-4 p-0' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                        <IconButton>
                            <FaHeartbeat size={'20px'} />
                        </IconButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '9px', marginBottom: '5px' }}>
                        <p style={{ margin: '0', marginBottom: '-2px', color: parseInt(heartRate) < 60 ? '#EC9C00' : parseInt(heartRate) > 100 ? 'red' : 'grey' }}>{heartRate}</p>
                        <p style={{ margin: '0' }}>Heart Rate</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;